import { For, Show, Switch, Match, createEffect } from "solid-js";
import { A } from "@solidjs/router";
import { Icon } from "solid-heroicons";
import { xCircle } from "solid-heroicons/solid";
import { imageUrl, PT } from "~/utils/products";
import Button from "~/components/Button";
import {
  useCheckoutContext,
  useSiteContext,
  useSessionContext,
} from "~/utils/contexts";
import { InfoBox } from "~/components/utility";
import { numberWithCommas } from "~/utils/helpers";
import { ProductImage } from "~/components/product";
import { EventType } from "@solid-primitives/analytics";
import { trackFBQ } from "~/utils/analytics";
import { RouteSectionProps } from "@solidjs/router";
import { isServer } from "solid-js/web";
import {
  LineDetails,
  QtyCounter,
  LineImage,
} from "~/components/ordering/checkout";

import { clientOnly } from "@solidjs/start";

// const ClientQtyCounter = clientOnly(()=> import("~/components/ordering/checkout/QtyCounter").then((mod)=>({default: mod.QtyCounter})))

// const ClientFavouriteHeartButton = clientOnly(() =>
//   import("./FavouriteHeartButton").then((mod) => ({
//     default: mod.FavouriteHeartButton,
//   }))
// );

// TODO - deal with all components/buttons that are reading from sending() -> can use useSubmission().pending instead?

//? import { productTypesDictionary } from "~/components/ecommerce/ordering/comps/TypeSelector";
//? import {
//   cuttingDictionary,
//   cuttingInstrOptions,
// } from "~/components/ecommerce/ordering/types/Length";
//? import { wedgeDictionary } from "~/components/ecommerce/ordering/comps/WedgeLock";
//? import { allowanceDisplay } from "~/components/ecommerce/ordering/comps/Allowance";

export default function Overview(props: RouteSectionProps) {
  const { aCartActionIsPending, session } = useSessionContext();
  const {
    cart,
    lines,
    minAmountMet,
    clearCart,
    disableCheckoutButtons,
  } = useCheckoutContext();
  const { breakpoints, track, trackFBQ, } = useSiteContext();


  return (
    <Switch>
      <Match when={cart() && cart()?.Status === "active"}>
        <div class="md:col-span-8 flex flex-col gap-8 sm:gap-16">
          <div>
            <Show when={minAmountMet().valid === false}>
              <InfoBox class="mb-6" type="warning">
                <p>
                  Orders containing {minAmountMet().name} must meet a minumum
                  value of ${minAmountMet().minAmount}.
                </p>
                <p>
                  Please add ${minAmountMet().difference.toFixed(2)} of{" "}
                  {minAmountMet().name} to your cart to proceed.
                </p>
              </InfoBox>
            </Show>
            <div class="flex justify-between">
              <div class="w-1/3">
                <h3 class="text-base font-medium">
                  Products ({lines()?.products.length})
                </h3>
              </div>
              <div class="hidden w-1/3 lg:flex justify-end gap-12">
                <h3 class="text-base font-medium col-start-3 text-right">
                  Unit Price
                </h3>
                <h3 class="text-base font-medium text-right">Total</h3>
              </div>
            </div>
            <div class="child:py-6 child:border-b-roma-grey child:border-b">
              <Show
                when={cart() && cart()?.LineCount! > 0}
                fallback={
                  <div class="bg-roma-grey rounded-md p-4 mt-4 text-center text-roma-dark-grey">
                    This cart has no items in it.
                    <br />
                    Add items from the{" "}
                    <A href="/order" class="text-roma-blue">
                      Order
                    </A>{" "}
                    page, or view products in the{" "}
                    <A href="/shop" class="text-roma-blue">
                      Shop
                    </A>
                    .
                  </div>
                }
              >
                <For each={lines()?.products}>
                  {(line) => {
                    return (
                      <div
                        class="flex justify-between lg:items-center gap-2 sm:gap-12"
                        classList={{
                          "border !border-red-100 bg-red-50 -mx-5 px-5":
                            !!line?.RequiresBackorderSelection,
                        }}
                      >
                        <div class="flex flex-col lg:flex-row justify-between items-start lg:items-center lg:w-3/4 gap-4 lg:gap-10 ">
                          <div class="flex items-start">
                            <LineImage
                              line={line}
                              size={
                                !isServer && !breakpoints.md ? "sm" : undefined
                              }
                            />
                            <LineDetails line={{ ...line }} />
                          </div>
                          <QtyCounter
                            disabled={aCartActionIsPending()}
                            quantity={
                              line.Type == PT.LENGTH || line.Type == PT.CONTRACT
                                ? line.CalculatedQuantity
                                : line.Quantity
                            }
                            productType={line.Type}
                            id={line.ID}
                            line={line}
                          />
                        </div>
                        {/* PRICING CONTAINER */}
                        <div class="lg:w-1/4 flex flex-col justify-between lg:flex-row items-end lg:justify-around gap-4 lg:gap-1 ">
                          <div class="text-right lg:text-center lg:w-1/2">
                            <p class="lg:hidden text-xs text-roma-medium-grey">
                              Unit Price
                            </p>
                            <p>
                              $
                              {line.Type == PT.LENGTH ||
                              line.Type == PT.CONTRACT
                                ? (
                                    line.Amount / line.CalculatedQuantity
                                  ).toFixed(2)
                                : (line.Amount / line.Quantity).toFixed(2)}
                            </p>
                            <Show when={Math.abs(line.Discount) > 0}>
                              <p class="text-sm text-roma-medium-grey line-through">
                                $
                                {line.Type == PT.LENGTH ||
                                line.Type == PT.CONTRACT
                                  ? (
                                      (line.Amount + Math.abs(line.Discount)) /
                                      line.CalculatedQuantity
                                    ).toFixed(2)
                                  : (
                                      (line.Amount + Math.abs(line.Discount)) /
                                      line.Quantity
                                    ).toFixed(2)}
                              </p>
                            </Show>
                          </div>
                          <div class="text-right max-lg:mb-5  lg:w-1/2">
                            <p class="lg:hidden text-sm text-roma-medium-grey">
                              Total
                            </p>
                            <p class="max-lg:text-xl">
                              ${numberWithCommas(line.Amount.toFixed(2))}
                            </p>
                            <Show when={Math.abs(line.Discount) > 0}>
                              <p class="text-sm text-roma-medium-grey line-through">
                                $
                                {(
                                  line.Amount + Math.abs(line.Discount)
                                ).toFixed(2)}
                              </p>
                            </Show>
                          </div>
                        </div>
                        {/* END OF PRICING CONTAINER */}
                      </div>
                    );
                  }}
                </For>
              </Show>
            </div>
          </div>
          {/* SAMPLES */}
          <Show when={lines()?.samples.length !== 0}>
            <div class="">
              <div class="flex flex-col ">
                <h3 class="text-base font-medium">
                  Samples ({lines()?.samples.length})
                </h3>
                <p class="text-roma-dark-grey text-sm font-light">
                  Corner samples ship free in a separate order.
                </p>
              </div>
              <div class="child:py-6 child:border-b-roma-grey child:border-b">
                <For each={lines()?.samples}>
                  {(line) => {
                    const item = line.SubItems[0];
                    return (
                      <div class="flex justify-between md:items-center gap-12">
                        <div class="flex flex-col md:flex-row justify-between items-start md:items-center w-3/4 gap-4 md:gap-12">
                          <div class="flex items-start">
                            <span class="inline-block flex-shrink-0 bg-roma-grey h-20 w-20">
                              <ProductImage
                                class="p-2"
                                src={imageUrl(item?.Moulding, line.Type, 1)}
                                width={80}
                                height={80}
                                quality={90}
                                alt=""
                              />
                            </span>
                            <div class="ml-4 self-start ">
                              <p class="text-base font-bold text-gray-900">
                                {item?.Moulding}
                              </p>
                              <p class="truncate text-sm font-light text-roma-dark-grey">
                                Corner Sample
                              </p>
                            </div>
                          </div>
                          <QtyCounter
                            line={line}
                            quantity={line.Quantity}
                            id={line.ID}
                            disabled={true}
                            productType={line.Type}
                          />
                        </div>
                        <div class="lg:w-1/4 flex justify-end gap-20">
                          {/* HIDING CORNER SAMPLE PRICING */}
                          <div class="text-right">
                            <Show when={line.Amount !== 0}>
                              <p class="md:hidden text-xs text-roma-medium-grey">
                                Unit Price
                              </p>
                              <p>${line.Amount.toFixed(2)}</p>
                            </Show>
                          </div>
                        </div>
                      </div>
                    );
                  }}
                </For>
              </div>
            </div>
          </Show>
          {/* END SAMPLES */}
          {/* FOOTER */}
          <div class="flex flex-col gap-4 md:flex-row justify-between md:items-center">
            <button
              disabled={aCartActionIsPending() || disableCheckoutButtons()}
              aria-label="Empty shopping cart"
              onClick={() => clearCart()}
              class="text-sm text-roma-blue flex items-center disabled:text-roma-medium-grey disabled:cursor-not-allowed"
            >
              <Icon path={xCircle} class="inline w-5 h-5" />
              <span class="ml-2">Empty Shopping Cart</span>
            </button>
            <Button
              href={`/checkout/${cart()?.ID}/review`}
              disabled={
                disableCheckoutButtons()
                // aCartActionIsPending() ||
                // minAmountMet().valid == false ||
                // cart()?.LineCount === 0 ||
                // cart()?.ProductRequiresBackorder ||
                // BPSContainsNonCorner()
              }
              onClick={() => {
                track(EventType.Event, {
                  category: "begin_checkout",
                  // @ts-expect-error
                  currency: session()?.currency,
                  value: cart()?.Amount?.toString(),
                  items: cart()?.Lines.map((item) => {
                    const category =
                      cart()?.Products?.[item.SubItems[0]?.Moulding]?.Category;
                    const collection =
                      cart()?.Products?.[item.SubItems[0]?.Moulding]?.Collection;
                    return {
                      item_id: item.SubItems[0].SKU,
                      item_name: `${category}, ${collection}`,
                      price: item.Amount,
                      discount: item.Discount,
                      quantity: item.CalculatedQuantity,
                      item_category: item.Type,
                      item_category2: category,
                      item_category3: collection,
                    };
                  }),
                });
                // pixel tracking:
                const { content_ids, contents } = cart()?.Lines?.reduce(
                  (memo, item) => {
                    memo.content_ids.push(`${item.SubItems[0].SKU}`);
                    memo.contents.push({
                      id: `${item.SubItems[0].SKU}`,
                      quantity: item.Quantity,
                    });
                    return memo;
                  },
                  {
                    content_ids: [] as string[],
                    contents: [] as {
                      id: string;
                      quantity: number;
                    }[],
                  }
                ) ?? { content_ids: [], contents: [] };
                trackFBQ("InitiateCheckout", {
                  currency: session()?.currency as string,
                  value: cart()?.Amount,
                  num_items: cart()?.LineCount,
                  content_ids: content_ids,
                  contents: contents,
                });
              }}
              class="max-md:w-full"
            >
              <span class=" w-full px-10 text-center">Continue Checkout</span>
            </Button>
          </div>
          {/* END FOOTER */}
        </div>
      </Match>
    </Switch>
  );
}
